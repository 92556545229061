<template>
    <div class="card-toolbar flex-row justify-content-end gap-5">
        <!-- <div class="col-2"> -->
        <div class="mw-150px">
            <select class="form-select form-select-solid" data-control="select2" data-hide-search="true" data-placeholder="Status" data-kt-ecommerce-product-filter="status">
                <option value="all">Todos</option>
                <option value="published">Activos</option>
                <option value="scheduled">Inactivos</option>
            </select>
        </div>

        <button 
            type="button" 
            class="btn btn-primary btn-active-light-primary"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            :title="'Filtrar'"
            @click="handleAction('F')"
        >
            <i class="bi bi-funnel text-center fs-2" />
            Filtrar
        </button>
        <button 
            type="button" 
            class="btn btn-primary btn-active-light-primary"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            :title="'Imprimir'"
        >
            <i class="bi bi-printer text-center fs-2" />
            Imprimir
        </button>
        <button 
            type="button" 
            class="btn btn-primary btn-active-light-primary"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            :title="'Exportar'"
        >
            <i class="bi bi-file-earmark-excel text-center fs-2" />
            Exportar
        </button>
        <button 
            type="button" 
            class="btn btn-primary btn-active-light-primary"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            :title="'Cambiar Precio'"
            @click="handleAction('CP')"
        >
            <i class="bi bi-currency-exchange text-center fs-2" />
            Cambiar Precios
        </button>
        <button 
            type="button" 
            class="btn btn-primary btn-active-light-primary"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            :title="'Agregar Artículo'"
            @click="goToNewArticleModule"
        >
            <i class="bi bi-plus-circle text-center fs-2" />
            Nuevo Artículo
        </button>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useRouter } from "vue-router";

export default defineComponent({
    name: "main-toolbar",
    setup(props, context) {
        const router = useRouter();
        const goToNewArticleModule = () => {
            let dataProps = `{"articleId":"", "articleCode": "", "action": "N"}`;
            router.push({
                name: 'editarticles',
                params: {
                    C0: btoa(dataProps)
                }
            });
        }

        const handleAction = (action) => {
            context.emit("onClickToolbarAction", action)
        }

        return {
            goToNewArticleModule,
            handleAction
        }
    },
})
</script>
