<template>
    <modal-form-master
        id="modalCatalogFilters"
        :title="$t('TITLE')"
        :readOnly="false"
        :textSaveBtn="'Aplicar'"
        :showExtraButton="true"
        :textExtraBtn="'Limpiar'"
        :fnCallbackSubmit="handleCrudAction"
        :validationSchema="validationSchema"
        @onClickNavBtn="onClickCleanBtn"
        ref="modalForm"
    >
        <row-form :twoColums="true">
            <template v-slot:default>
                <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.WAREHOUSE") }}</label>
                <Field  name="WarehouseId">
                    <select-option 
                        class="itemControl"
                        name="WarehouseId"
                        :options="warehouseOptions"
                        :disabled="false"
                    />
                </Field>
            </template>
            <template v-slot:col2>
                <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.NAME") }}</label>
                <Field  name="Name" v-slot="{value, field, errorMessage}">
                    <el-form-item :error="errorMessage">
                        <el-input
                            v-bind="field"
                            class="itemControl"
                            type="text" 
                            :placeholder="$t('PH.NAME')"
                            :validate-event="false"
                            :disabled="false"
                            :model-value="value"
                        />
                    </el-form-item>
                </Field>
            </template>
        </row-form>

        <row-form :twoColums="true">
            <template v-slot:default>
                <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.CODE") }}</label>
                <Field  name="Code" v-slot="{value, field, errorMessage}">
                    <el-form-item :error="errorMessage">
                        <el-input
                            v-bind="field"
                            class="itemControl"
                            type="text" 
                            :placeholder="$t('PH.CODE')"
                            :validate-event="false"
                            :disabled="false"
                            :model-value="value"
                        />
                    </el-form-item>
                </Field>
            </template>
            <template v-slot:col2>
                <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.BAR_ALTERNATE") }}</label>
                <Field  name="AlternateCode" v-slot="{value, field, errorMessage}">
                    <el-form-item :error="errorMessage">
                        <el-input
                            v-bind="field"
                            class="itemControl"
                            type="text" 
                            :placeholder="$t('PH.BAR_ALTERNATE')"
                            :validate-event="false"
                            :disabled="false"
                            :model-value="value"
                        />
                    </el-form-item>
                </Field>
            </template>
        </row-form>
        <row-form>
            <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.DESCRIPTION") }}</label>
            <Field  name="Description" v-slot="{value, field, errorMessage}">
                <el-form-item :error="errorMessage">
                    <el-input
                        v-bind="field"
                        class="largeItemControl"
                        type="text" 
                        :placeholder="$t('PH.DESCRIPTION')"
                        :validate-event="false"
                        :disabled="false"
                        :model-value="value"
                    />
                </el-form-item>
            </Field>
        </row-form>

        <row-form :twoColums="true">
            <template v-slot:default>
                <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.FAMILY") }}</label>
                <Field  name="FamilyId">
                    <select-option 
                        class="itemControl"
                        name="FamilyId"
                        :options="familyOptions"
                        :disabled="false"
                        @changeSelectValue="onChangeFamily"
                    />
                </Field>
            </template>
            <template v-slot:col2>
                <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.CLASS") }}</label>
                <Field  name="SubfamilyId">
                    <select-option 
                        class="itemControl"
                        name="SubfamilyId"
                        :options="subFamilyOptions"
                        :disabled="false"
                    />
                </Field>
            </template>
        </row-form>

        <row-form :twoColums="true">
            <template v-slot:default>
                <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.COLOR") }}</label>
                <Field  name="ColorId">
                    <select-option 
                        class="itemControl"
                        name="ColorId"
                        :options="colorOptions"
                        :disabled="false"
                    />
                </Field>
            </template>
            <template v-slot:col2>
                <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.SUPPLIER") }}</label>
                <Field  name="SupplierId">
                    <select-option 
                        class="itemControl"
                        name="SupplierId"
                        :options="supplierOptions"
                        :disabled="false"
                    />
                </Field>
            </template>
        </row-form>
    </modal-form-master>
</template>

<script>
import { computed, defineComponent, ref, onBeforeMount } from "vue";
import ModalFormMaster from "../../components/c-modal-form/ModalFormMaster.vue";
import RowForm from "../../components/c-form-elements/RowForm.vue";
import { Field } from "vee-validate";
import * as Validations from "yup";
import SelectOption from "../../components/c-form-elements/SelectOption.vue";
import ApiService from "@/core/services/ApiService";
import SwalMessageService from "@/core/services/SwalMessageService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';

export default defineComponent({
    name: "catalog-filters",
    components: {
        ModalFormMaster,
        RowForm,
        Field,
        SelectOption
    },
    setup(props, context) {
        const messageService = new SwalMessageService();
        const modalForm = ref(null);
        let selectedInGrid = ref(true);
        let warehouses = ref([]);
        let families = ref([]);
        let subFamilies = ref([]);
        let colors = ref([]);
        let suppliers = ref([]);

        const validationSchema = Validations.object().shape({
            Code: Validations.string().label("Code"),
            Description: Validations.string().label("Description"),
            AlternateCode: Validations.string().label("AlternateCode"),
            Name: Validations.string().label("Name"),
            WarehouseId: Validations.string().label("WarehouseId"),
            FamilyId: Validations.string().label("FamilyId"),
            SubfamilyId: Validations.string().label("SubfamilyId"),
            SupplierId: Validations.string().label("SupplierId"),
            ColorId: Validations.string().label("ColorId")
        });

        onBeforeMount(async() => {
            await getComboOptions("Warehouses", warehouses);
            await getComboOptions("Families", families);
            await getComboOptions("Colors", colors);
            await getComboOptions("Suppliers", suppliers);
        });

        const activeFilters = computed(() => selectedInGrid.value);
        const warehouseOptions = computed(() => warehouses.value);
        const familyOptions = computed(() => families.value);
        const subFamilyOptions = computed(() => subFamilies.value);
        const colorOptions = computed(() => colors.value);
        const supplierOptions = computed(() => suppliers.value);

        const initDefaults = () => {
            modalForm.value.$refs.form.setFieldValue("Code", "");
            modalForm.value.$refs.form.setFieldValue("Description", "");
            modalForm.value.$refs.form.setFieldValue("AlternateCode", "");
            modalForm.value.$refs.form.setFieldValue("Name", "");
            modalForm.value.$refs.form.setFieldValue("WarehouseId", "");
            modalForm.value.$refs.form.setFieldValue("FamilyId", "");
            modalForm.value.$refs.form.setFieldValue("SubfamilyId", "");
            modalForm.value.$refs.form.setFieldValue("SupplierId", "");
            modalForm.value.$refs.form.setFieldValue("ColorId", "");
            modalForm.value.$refs.form.resetForm(); 
        }

        const onClickCleanBtn = (action) => {
            initDefaults();
            context.emit("onCleanFilters", "");
        }

        const handleCrudAction = async(values) => {
            context.emit("onApplyFilters", values);
            modalForm.value.closeModal();
        }

        const getComboOptions = async(resource, container, parentId = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo`;
            ApiService.get(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const onChangeFamily = async(famId) => {
            if(famId) {
                subFamilies.value = [];
                modalForm.value.$refs.form.setFieldValue("SubfamilyId", "");
                await getComboOptions("subfamilies", subFamilies, famId);
            }
        }

        return {
            modalForm
            , validationSchema

            //Computeds
            , activeFilters
            , warehouseOptions
            , familyOptions
            , subFamilyOptions
            , colorOptions
            , supplierOptions

            //Functions
            , initDefaults
            , onClickCleanBtn
            , handleCrudAction
            , onChangeFamily
        }
    },
});
</script>

<style lang="scss">
    .itemControl {
        width: 250px;
    }

    .largeItemControl {
        width: 510px;
    }
</style>

<i18n>
{
    "es": {
        "TITLE": "Filtros",
        "FIELDS": {
            "WAREHOUSE": "Almacén",
            "NAME": "General",
            "CODE": "Código",
            "BAR_ALTERNATE": "Alterno",
            "DESCRIPTION": "Descripción",
            "FAMILY": "Familia",
            "CLASS": "Clase",
            "COLOR": "Color",
            "SUPPLIER": "Proveedor"
        },
        "PH": {
            "WAREHOUSE": "Almacén",
            "NAME": "General",
            "CODE": "Código",
            "BAR_ALTERNATE": "Alterno",
            "DESCRIPTION": "Descripción",
            "FAMILY": "Familia",
            "CLASS": "Clase",
            "COLOR": "Color",
            "SUPPLIER": "Proveedor"
        }
    }
}
</i18n>