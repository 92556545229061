<template>
    <modal-form-master
        id="modalChangePrices"
        :title="$t('TITLE')"
        :readOnly="false"
        ref="modalForm"
    >
        <row-form :twoColums="true">
            <template v-slot:default>
                <div class="form-check form-check-custom form-check-solid mb-2">
                    <input class="form-check-input" type="checkbox" :checked="activeFilters" @change="onChangeSelectCheck"/>
                    <label class="required fs-6 fw-bold mb-2" style="margin-left: 16px;">¿Seleccionados en Grid?</label>
                </div>
            </template>
        </row-form>
        <row-form :twoColums="true">
            <template v-slot:default>
                <label class="required fs-6 fw-bold mb-2">Línea</label>
                <Field  name="WherehouseId">
                    <select-option 
                        class="itemControl"
                        name="line"
                        :options="lines"
                        :disabled="activeFilters"
                        @change="onChangeLine"
                    />
                </Field>
            </template>
            <template v-slot:col2>
                <label class="required fs-6 fw-bold mb-2">Proveedor</label>
                <Field  name="supplierId">
                    <select-option 
                        class="itemControl"
                        name="supplierId"
                        :options="suppliersLine"
                        :disabled="activeFilters"
                    />
                </Field>
            </template>
        </row-form>
        <row-form :twoColums="true">
            <template v-slot:default>
                <label class="d-flex align-items-center fs-6 fw-semobold form-label mb-2">
                    <span class="required">{{ $t("COLNAMES.COST_INCREASE") }}</span>
                    <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" :title="$t('COLTOOLTIPS.COST_INCREASE')"></i>
                </label>
                <Field  name="price" v-slot="{value, field, errorMessage}">
                    <el-form-item :error="errorMessage">
                        <el-input
                            v-bind="field"
                            class="itemControl"
                            type="text" 
                            :placeholder="$t('COLNAMES.COST_INCREASE')"
                            :validate-event="false"
                            :disabled="false"
                            :model-value="value"
                        />
                    </el-form-item>
                </Field>
            </template>
            <template v-slot:col2>
                <label class="d-flex align-items-center fs-6 fw-semobold form-label mb-2">
                    <span class="required">{{ $t("COLNAMES.M1") }}</span>
                    <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" :title="$t('COLTOOLTIPS.M1')"></i>
                </label>
                <Field  name="price" v-slot="{value, field, errorMessage}">
                    <el-form-item :error="errorMessage">
                        <el-input
                            v-bind="field"
                            class="itemControl"
                            type="text" 
                            :placeholder="$t('COLNAMES.M1')"
                            :validate-event="false"
                            :disabled="false"
                            :model-value="value"
                        />
                    </el-form-item>
                </Field>
            </template>
        </row-form>
        <row-form :twoColums="true">
            <template v-slot:default>
                <label class="d-flex align-items-center fs-6 fw-semobold form-label mb-2">
                    <span class="required">{{ $t("COLNAMES.M2") }}</span>
                    <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" :title="$t('COLTOOLTIPS.M2')"></i>
                </label>
                <Field  name="price" v-slot="{value, field, errorMessage}">
                    <el-form-item :error="errorMessage">
                        <el-input
                            v-bind="field"
                            class="itemControl"
                            type="text" 
                            :placeholder="$t('COLNAMES.M2')"
                            :validate-event="false"
                            :disabled="false"
                            :model-value="value"
                        />
                    </el-form-item>
                </Field>
            </template>
            <template v-slot:col2>
                <label class="d-flex align-items-center fs-6 fw-semobold form-label mb-2">
                    <span class="required">{{ $t("COLNAMES.M3") }}</span>
                    <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" :title="$t('COLTOOLTIPS.M3')"></i>
                </label>
                <Field  name="price" v-slot="{value, field, errorMessage}">
                    <el-form-item :error="errorMessage">
                        <el-input
                            v-bind="field"
                            class="itemControl"
                            type="text" 
                            :placeholder="$t('COLNAMES.M3')"
                            :validate-event="false"
                            :disabled="false"
                            :model-value="value"
                        />
                    </el-form-item>
                </Field>
            </template>
        </row-form>
    </modal-form-master>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import ModalFormMaster from "../../components/c-modal-form/ModalFormMaster.vue";
import RowForm from "../../components/c-form-elements/RowForm.vue";
import { Field } from "vee-validate";
import * as Validations from "yup";
import SelectOption from "../../components/c-form-elements/SelectOption.vue";

export default defineComponent({
    name: "change-prices",
    components: {
        ModalFormMaster,
        RowForm,
        Field,
        SelectOption
    },
    setup() {
        const modalForm = ref(null);
        let selectedInGrid = ref(true);
        let selectedSupplierId = ref(null);
        let lines = [
            { id: 1, name: "Ferrosos" }, { id: 2, name: "No Ferrosos" }, { id: 3, name: "Vidrios" }
        ];
        
        let suppliers = [
            { id: 1, name: "Proveedor 1", supplierId: 1 },
            { id: 2, name: "Proveedor 2", supplierId: 1 },
            { id: 3, name: "Proveedor 3", supplierId: 2 },
            { id: 4, name: "Proveedor 4", supplierId: 2 },
            { id: 5, name: "Proveedor 5", supplierId: 3 },
            { id: 6, name: "Proveedor 6", supplierId: 3 },
        ]

        const activeFilters = computed(() => selectedInGrid.value);
        const suppliersLine = computed(() => suppliers.filter(s => s.supplierId == selectedSupplierId.value));

        const initDefaults = () => {
            selectedInGrid.value = true;
            selectedSupplierId.value = null;
        }

        const onChangeSelectCheck = (event) => {
            selectedInGrid.value = event.currentTarget.checked;
        }

        const onChangeLine = (lineId) => {
            selectedSupplierId.value = lineId;
        }

        return {
            modalForm
            , lines
            , activeFilters
            , suppliersLine
            , initDefaults
            , onChangeSelectCheck
            , onChangeLine
        }
    },
});
</script>

<style lang="scss">
    .itemControl {
        width: 250px;
    }
</style>

<i18n>
{
    "es": {
        "TITLE": "Cambiar Precios",
        "COLNAMES": {
            "COST_INCREASE": "% Incremento al costo",
            "M1": "M1",
            "M2": "M2",
            "M3": "M3"
        },
        "COLTOOLTIPS": {
            "COST_INCREASE": "Incremento del costo del artículo",
            "M1": "Actualización del precio público",
            "M2": "Actualización del precio aluminiero",
            "M3": "Actualización del precio mayoreo"
        }
    }
}
</i18n>