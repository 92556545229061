export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar Precios"])},
        "COLNAMES": {
          "COST_INCREASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% Incremento al costo"])},
          "M1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M1"])},
          "M2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M2"])},
          "M3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M3"])}
        },
        "COLTOOLTIPS": {
          "COST_INCREASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incremento del costo del artículo"])},
          "M1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización del precio público"])},
          "M2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización del precio aluminiero"])},
          "M3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización del precio mayoreo"])}
        }
      }
    }
  })
}
